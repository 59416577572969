<template id="tmplNewOrderTender">
  <div id="divNewOrderTenderParent" class="container vld-parent">
    <loading
      id="ldNewOrderTender"
      :active.sync="isLoading"
      :is-full-page="true"
      color="#114C8E"
    ></loading>
    <b-row id="rowHeaderArea">
      <h2 id="hdrTenderOrder" class="text-primary">Tender Order</h2>
    </b-row>
    <b-row>
      <b-card id="crdOrderTender" style="width: 100%">
        <b-row id="rowUseTemplate" class="text-right">
          <b-col>
            <b-button
              id="btnUseTemplate"
              variant="danger"
              @click="showTemplateSlideOut()"
              >Use Template</b-button
            >
          </b-col>
        </b-row>
        <b-row id="rowOrderTenderValidationModal">
          <b-col id="colOrderTenderValidationModal">
            <DeliveryDetails
              id="secDeliveryDetails"
              Mode="Create"
              :ValidationModel="$v"
            />
            <hr id="hrDeliveryDetailsLine" class="divider-margin" />
            <ProductDetails id="secProductDetails" />
            <hr id="hrProductDetailsLine" class="divider-margin" />
            <SpecialInstructions id="secSpecialInstructions" />
            <hr id="hrSpecialInstructionsSection" class="divider-margin" />
            <TemplateDetails id="secTemplateDetails" :ValidationModel="$v" />
          </b-col>
        </b-row>
        <b-row>
          <b-col id="colReviewOrderTender" class="text-right">
            <b-button
              id="btnReviewOrderTender"
              variant="primary"
              @click="showOrderReviewModal()"
              >Review Order Tender</b-button
            >
          </b-col>
        </b-row>
        <b-row id="rowTenderError">
          <b-col
            id="colTenderError"
            class="text-right pd-10"
            v-if="$v.$anyError"
          >
            <span id="spnTenderError" class="text-danger"
              >Tender has errors! Please fix them and try again.</span
            >
          </b-col>
        </b-row>
      </b-card>
    </b-row>
    <!-- Order Confirmation Modal -->
    <b-modal
      id="mdlOrderconfirmation"
      v-model="showOrderConfirmationModal"
      :title="orderConfirmationModalTitle"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      size="lg"
      @hide="modalClosed()"
    >
      <b-row id="rowOrderSubmittedSuccessfully" v-if="submitSuccessful">
        <b-col id="colOrderSubmittedSuccessfully">
          <b-alert variant="success" class="text-center" show
            >Order Submitted Successfully</b-alert
          >
        </b-col>
      </b-row>
      <b-row id="rowTenderId" v-if="tenderId">
        <b-col id="colTenderId">
          <span id="spnTenderId" class="lead">Tender Id:</span>
          &nbsp;
          <label id="lblTenderId" class="text-primary font-weight-bolder">{{
            tenderId
          }}</label>
        </b-col>
      </b-row>
      <b-row id="rowOrderId" v-if="orderId">
        <b-col id="colOrderId">
          <span id="spnOrderId" class="lead">Order Id:</span>
          &nbsp;
          <label id="lblOrderId" class="text-primary font-weight-bolder">{{
            orderId
          }}</label>
        </b-col>
      </b-row>
      <b-row id="rowDeliveryDate" class="mt-1">
        <b-col id="colDeliveryDate">
          <span id="spnDeliveryDate" class="lead">Delivery Date:</span>
          &nbsp;
          <label id="lblDeliveryDate" class="text-primary font-weight-bolder">
            {{ formattedDateTime }}
          </label>
        </b-col>
      </b-row>
      <b-row id="rowPoSalesNo" class="mt-1">
        <b-col id="colPoSalesNo">
          <span id="spnPoSalesNo" class="lead">PO/Sales #:</span>
          &nbsp;
          <label id="lblPoSalesNo" class="text-primary font-weight-bolder">
            {{ poNumber != "" ? poNumber : "N/A" }}
          </label>
        </b-col>
      </b-row>
			<b-row id="row3PoSalesNo" class="mt-1">
        <b-col id="col3PoSalesNo">
          <span id="spn3PoSalesNo" class="lead">3PO/Sales #:</span>
          &nbsp;
          <label id="lblPoSalesNo" class="text-primary font-weight-bolder">
            {{ thirdPartyPO != "" ? thirdPartyPO : "N/A" }}
          </label>
        </b-col>
      </b-row>
      <b-row id="rowNotes" class="mt-1">
        <b-col id="colNotes">
          <span id="spnNotes" class="lead">Notes:</span>
          &nbsp;
          <label id="lblNotes" class="text-primary font-weight-bolder">
            {{ notes != "" ? notes : "N/A" }}
          </label>
        </b-col>
      </b-row>
      <b-row id="rowProducts" class="text-primary mt-3">
        <b-col id="colProducts">
          <h4 id="hdrProducts">Products</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            id="tblProductInformation"
            ref="productConfirmationModalTable"
            :items="selectedProducts"
            :small="true"
            :responsive="true"
            :fields="tableDefaultFields"
          >
            <template id="tmplDeliverySite" v-slot:cell(deliverySite)="row">
              <div
                id="divDeliverySite"
                class="text-wrap text-break"
                style="width: 120px"
              >
                {{ getDeliveryAddress(row.value, "N/A") }}
              </div>
            </template>
            <template id="tmplShipper" v-slot:cell(shipper)="row">
              <div
                id="divShipper"
                class="text-wrap text-break"
                style="width: 120px"
              >
                {{ getDeliveryAddress(row.value, "N/A") }}
              </div>
            </template>
            <template id="tmplSupplier" v-slot:cell(supplier)="row">
              <div
                id="divSupplier"
                class="text-wrap text-break"
                style="width: 120px"
              >
                {{ getDeliveryAddress(row.value, "N/A") }}
              </div>
            </template>
            <template id="tmplAccountOf" v-slot:cell(accountOf)="row">
              {{ row.value ? row.value.name : "N/A" }}
            </template>
            <template id="tmplProduct" v-slot:cell(product)="row">
              <div id="divProduct" style="width: 120px">
                {{ row.value ? row.value.name : "N/A" }}
              </div>
            </template>
            <template id="tmplLoadWindow" v-slot:cell(loadWindow)="row">
              <div id="divLoadWindow" style="width: 150px">
                {{ row.value ? getDateIntervalFormatted(row.value) : "N/A" }}
              </div>
            </template>
            <template id="tmplDeliveryWindow" v-slot:cell(deliveryWindow)="row">
              <div id="divDeliveryWindow" style="width: 150px">
                {{ row.value ? getDateIntervalFormatted(row.value) : "N/A" }}
              </div>
            </template>
            <template id="tmplCommdityOne" v-slot:cell(commodityOne)="row">
              <div id="divCommdityOne" style="width: 120px">
                {{ row.value ? row.value.name : "N/A" }}
              </div>
            </template>
            <template id="tmplCommodityTwo" v-slot:cell(commodityTwo)="row">
              <div id="divCommodityTwo" style="width: 120px">
                {{ row.value ? row.value.name : "N/A" }}
              </div>
            </template>
            <template id="tmplAmmount" v-slot:cell(amount)="row">
              {{ buildQuantityString(row) }}
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div
        id="divSubmitOrderTender"
        slot="modal-footer"
        class="w-100"
        v-if="!submitSuccessful"
      >
        <b-button
          id="btnSubmitOrderTender"
          class="float-right ml-2 mr-2"
          variant="primary"
          @click="SubmitnewOrderTender()"
          >Submit Order Tender</b-button
        >
        <b-button
          id="btnCancel"
          class="float-right"
          variant="default"
          @click="showOrderConfirmationModal = false"
          >Cancel</b-button
        >
      </div>
      <div
        id="divNewOrderTenderSubmitSuccessful"
        slot="modal-footer"
        class="w-100"
        v-if="submitSuccessful"
      >
        <b-button
          id="btnClose"
          class="float-right"
          variant="primary"
          @click="clearNewOrderTenderData()"
          >Close</b-button
        >
      </div>
    </b-modal>
    <slideout-panel id="pnlNewOrderTenderSlideOut"></slideout-panel>
  </div>
</template>

<script>
/* eslint-disable */
//#region
import { mapState } from "vuex";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DeliveryDetails from "@/components/orders/DeliveryDetails";
import ProductDetails from "@/components/orders/ProductDetails";
import SpecialInstructions from "@/components/orders/SpecialInstructions";
import TemplateDetails from "@/components/orders/TemplateDetails";
import { handleRequestError } from "@/shared/utils/response-error-handler";
import OrderTemplatesSlideOut from "@/components/orders/OrderTemplatesSlideOut";
import { formatDisplayDate } from "@/shared/utils/date-formatter";
import mixin from "@/shared/mixin";
import { divisions } from "@/shared/constants";
import { dateToClientReadable } from "@/shared/utils/date-formatter";
//#endregion

export default {
  name: "NewOrderTender.vue",

  components: {
    DeliveryDetails,
    ProductDetails,
    SpecialInstructions,
    Loading,
    TemplateDetails,
  },

  mixins: [mixin],

  data() {
    return {
      submitSuccessful: false,
      errorText: "",
      showOrderConfirmationModal: false,
      orderConfirmationModalTitle: "Confirm Tender Details",
      isLoading: false,
      tenderId: null,
      orderId: null,
      amountUnitOfMeasure: null,
      spgTable: {
        fields: [
          "deliverySite",
          "shipper",
          "product",
          { loadWindow: "Load Window" },
          { deliveryWindow: "Delivery Window" },
          "amount",
          "actions",
        ],
        emptyText:
          "You have no products.  Please click the 'Add Product' button above to add some.",
      },
      table: {
        fields: [
          "deliverySite",
          "shipper",
          "supplier",
          "accountOf",
          "product",
          { loadWindow: "Load Window" },
          { deliveryWindow: "Delivery Window" },
          "amount",
        ],
        emptyText:
          "You have no products.  Please click the 'Add Product' button above to add some.",
      },
    };
  },
  async created() {
    try {
      //handles error modal display
      this.$root.$on("isEditLoading", (val) => (this.isLoading = val));

      //clear any existing tender order data
      this.$v.$reset();
      this.submitSuccessful = false;
      this.showOrderConfirmationModal = false;
      this.tenderId = null;
      this.orderId = null;
      

      //Load All BillTos
      await this.$store.dispatch(
        "newOrderTender/getAllBillTos",
        this.$store.state.user.jwtToken
      );

      //Load All Consignees
      if (this.$store.state.newOrderTender.billTos.length > 0) {
        await this.$store.dispatch("newOrderTender/getConsignees", {
          jwtToken: this.$store.state.user.jwtToken,
          billTo: this.$store.state.newOrderTender.billTos[0].id,
        });

        if (this.consignees.length == 0) {
          this.$snotify.error(
            "No consignees available. If you feel this is an error please contact support.",
            "Error"
          );
        }

        // if (!consigneesSuccess){
        //   this.$snotify.error("No consignees available.", "Error");
        // }
      }
    } catch (error) {
      var errorMessage = handleRequestError(error);
      this.$snotify.error(errorMessage, "Error");
    }
  },

  destroyed() {
    this.$store.dispatch("newOrderTender/clearOrderTenderData");
  },

  methods: {
    modalClosed() {
      if (this.submitSuccessful == true) {
        this.$v.$reset();
        this.submitSuccessful = false;
        this.showOrderConfirmationModal = false;
				if(this.templateId){
					this.$router.push({
						name: "order-templates",
					});
				}
				this.$store.dispatch("newOrderTender/clearOrderTenderData");
      }
    },

    showOrderReviewModal() {
      // check delivery date valiation -- only needed for not customized
      if (this.selectedDeliveryTime == null) {
        // Have date selected
        this.$snotify.error(
          "Delivery Date/Time not valid; re-select or use Custom!",
          "Error"
        );
        return;
      }
    //  if (this.selectedDeliveryTime == "custom") {
        /*
					NPB Clarification notes
					This check is to confirm the To or end date/time is 12 hours after the current date/time

					I am covering this because the original code would only get the end of the day (23:59) time portion
					and compare that against the time the order was being created.

					This was causing errors after 12:05 PM because the time difference was not greater than 12 hours

					Also there is currently no check to see if the start date/time is a reasonable value or
					if the window between Start and End is appropriate
				*/
        // let timemode = deliveryTimes[this.selectedDeliveryTime]; NOT IN USE
        //let start = this.getDateFromOutput(this.selectedDeliveryDate, timemode.start);
   //     let end = moment(
   //       `${this.selectedToCustomDate}T${this.selectedToCustomTime}`
   //     ); //NPB Explicitly getting the end date and time
   //     var endcutoff = end.diff(moment(), "minutes");
    //    if (endcutoff < deliveryWindows.endCutoffHours * 60) {
          // give 5 minutes delay
    //      this.$snotify.error(
    //        "Delivery Date/Time not valid; re-select or use Custom!",
     //       "Error"
    //    );
    //      return;
    //    }
   //   }
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.selectedProducts.length > 0) {
          //this.$refs.productConfirmationModalTable.refresh();
          this.showOrderConfirmationModal = true;
        } else {
          this.$snotify.error("You must add at least one product!", "Error");
        }
      }
    },


    async SubmitnewOrderTender() {
      try {
        this.showOrderConfirmationModal = false;
        this.isLoading = true;

        var billTo =
          this.$store.state.newOrderTender.selectedProducts[0].deliverySite
            .billTo;

        var division = this.$store.state.newOrderTender.billTos[0].division;
        var shipmentStartDateTime, shipmentEndDateTime;

        switch (this.selectedDeliveryTime) {
          case "morning":
            shipmentStartDateTime = moment(this.selectedDeliveryDate)
              .hours(6)
              .format();

            shipmentEndDateTime = moment(this.selectedDeliveryDate)
              .hours(12)
              .format();

            break;
          case "afternoon":
            shipmentStartDateTime = moment(this.selectedDeliveryDate)
              .hours(12)
              .format();

            shipmentEndDateTime = moment(this.selectedDeliveryDate)
              .hours(18)
              .format();
            break;
          case "evening":
            shipmentStartDateTime = moment(this.selectedDeliveryDate)
              .hours(18)
              .format();

            shipmentEndDateTime = moment(this.selectedDeliveryDate)
              .hours(23)
              .minutes(59)
              .seconds(59)
              .format();
            break;
          case "custom":
            shipmentStartDateTime = moment(
              `${this.selectedFromCustomDate}T${this.selectedFromCustomTime}`
            ).format(); //NPB Remove the specific 'MM-DD-YYYY' format to allow the time to be included otherwise it was only loading the date portion
            shipmentEndDateTime = moment(
              `${this.selectedToCustomDate}T${this.selectedToCustomTime}`
            ).format();
            break;
          default:
            break;
        }
        
        var objBillTo; //, objConsignee; NOT IN USE
        var selectedBillTo = _.find(this.billTos, { id: billTo });
				
        objBillTo = {
          id: selectedBillTo.id,
          name: selectedBillTo.name,
        };

        var details = [];
        var components = [];

        // let totalGallons = +this.selectedProducts.amount; NOT IN USE

        // let comOnePerct = this.selectedProducts.commodityOnePercentage; NOT IN USE
        this.selectedProducts.forEach((x) => {
          var comOne =
            (+x.commodityOnePercentage / 100) * (+x.amount).toFixed(2);
          var comTwo =
            (+x.commodityTwoPercentage / 100) * (+x.amount).toFixed(2);
          if (x.commodityOne !== undefined && x.commodityOne != "") {
            components.push({
              id: 202,
              amount: !isNaN(comOne) ? comOne : 0,
            });
            if (x.commodityOne !== undefined && x.commodityTwo != "") {
              components.push({
                id: 202,
                amount: !isNaN(comTwo) ? comTwo : 0,
              });
            }
          }
        });
        
        this.selectedProducts.forEach((stop) => {
          details.push({
            deliverySite: stop.deliverySite,
            shipper: stop.shipper,
            supplier: stop.supplier,
            accountOf: stop.accountOf,
            amount: stop.amount,
            product: stop.product,
            notes: "",
            components: components,
            amountUnitOfMeasure: stop.amountUnitOfMeasure,
            callForQuantity: stop.callForQuantity,
            loadWindow: stop.loadWindow,
            deliveryWindow: stop.deliveryWindow,
          });
        });

        var objOrderTender = {
          receiver: "TMWCLT",
          sender: "TRPFCS",
          division: division,
          billTo: objBillTo,
          shipmentStartDateTime: moment(shipmentStartDateTime).format('YYYY-MM-DDTHH:mm:ss'),
          shipmentEndDateTime: moment(shipmentEndDateTime).format('YYYY-MM-DDTHH:mm:ss'),
          po: this.poNumber,
          notes: this.notes,
          deliveryTimeWindow: this.selectedDeliveryTime,
          details: details,
          additionalReferenceNumber: "",
          amountUnitOfMeasure: this.amountUnitOfMeasure,
          saveTemplate: this.saveTemplate,
          saveTemplatePoNumber: this.saveTemplatePoNumber,
          templateName: this.templateName,
          templateId: this.templateId,
					thirdPartyPO: this.thirdPartyPO
        };

        var requestData = {
          orderTender: objOrderTender,
          jwtToken: this.$store.state.user.jwtToken,
        };

        var response = await this.$store.dispatch(
          "newOrderTender/submitOrderTender",
          requestData
        );

        this.tenderId = response.customId;
        this.orderId = response.kagOrderId;
        this.submitSuccessful = true;
        this.showOrderConfirmationModal = true;

      } catch (error) {
        console.log("ERROR:", error);
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }

      this.isLoading = false;
    },

    async clearNewOrderTenderData() {
      this.$v.$reset();
      this.submitSuccessful = false;
      this.showOrderConfirmationModal = false;
      if(this.templateId){
				this.$router.push({
					name: "order-templates",
				});
			}
			this.$store.dispatch("newOrderTender/clearOrderTenderData");
    },

    showTemplateSlideOut() {
      const panelInstance = this.$showPanel({
        component: OrderTemplatesSlideOut,
				openOn: 'right'
      });
      panelInstance.promise.then((result) =>
        console.log(
          "🚀 ~ file: NewOrderTender.vue ~ line 510 ~ showTemplateSlideOut ~ result",
          result
        )
      );
    },

    buildCommodityOneRow(row) {
      return (
        row.item.commodityOne +
        " " +
        (+row.item.commodityOnePercentage / 100) *
          (+row.item.amount).toFixed(2) +
        row.item.amountUnitOfMeasure
      );
    },

    buildCommodityTwoRow(row) {
      return (
        row.item.commodityTwo +
        " " +
        (+row.item.commodityTwoPercentage / 100) *
          (+row.item.amount).toFixed(2) +
        row.item.amountUnitOfMeasure
      );
    },

    buildQuantityString(row) {
      if (row.item.callForQuantity) {
        return "Call For Quantity";
      }

      return row.value + " " + row.item.amountUnitOfMeasure;
    },

    getDateIntervalFormatted(intervals) {
      var dateIntervals = intervals.split('/')
      return `${dateToClientReadable(dateIntervals[0])} ${dateToClientReadable(dateIntervals[1])}`
    }
  },
  computed: {
    ...mapState("newOrderTender", [
      "accountOfs",
      "billTos",
      "shippers",
      "suppliers",
      "commodities",
      "consignees",
      "selectedConsignee",
      "selectedDeliveryDate",
      "selectedDeliveryTime",
      "selectedFromCustomDate",
      "selectedFromCustomTime",
      "selectedToCustomDate",
      "selectedToCustomTime",
      "poNumber",
      "notes",
      "selectedProducts",
      "saveTemplate",
      "saveTemplatePoNumber",
      "templateName",
      "loadWindow",
      "deliveryWindow",
			"division",
			"thirdPartyPO"
    ]),

		templateId: {
			get() {
				return this.$store.state.newOrderTender.templateId
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setTemplateId", value);
      }
		},

    formattedDateTime() {
      var selectedFromDateTime, selectedToDateTime;

      if (this.selectedDeliveryTime == "custom") {
        selectedFromDateTime = moment(
          `${this.selectedFromCustomDate}T${this.selectedFromCustomTime}`
        );
        selectedToDateTime = moment(
          `${this.selectedToCustomDate}T${this.selectedToCustomTime}`
        );
      }

      return formatDisplayDate(
        this.selectedDeliveryTime,
        this.selectedDeliveryDate,
        selectedFromDateTime,
        selectedToDateTime
      );
    },

    tableDefaultFields() {
      const mapEmitter = new Map([
        [divisions.SPG, () => this.spgTable.fields],
        [
          divisions.FDG,
          () => {
            if (this.selectedProducts.some((e) => e.isAdvancedView)) {
              return this.table.fields;
            } else {
              return _.difference(this.table.fields, ["supplier", "accountOf"]);
            }
          },
        ],
      ]);

      if (this.selectedProducts.length) {
        let product = this.selectedProducts[0];
        return mapEmitter.get(
          product.deliverySite.division == divisions.FDG
            ? divisions.FDG
            : divisions.SPG
        )();
      } else {
        //just a default value to not throw errors
        return mapEmitter.get(divisions.FDG)();
      }
    },
  },

  validations() {
    if (this.selectedDeliveryTime != "custom") {
      if (this.saveTemplate) {
        return {
          selectedDeliveryTime: {
            required,
            //hasTwentyFourHourBuffer
          },
          selectedDeliveryDate: {
            required,
          },
          templateName: {
            //required
          },
        };
      } else {
        return {
          selectedDeliveryTime: {
            //required,
            //hasTwentyFourHourBuffer
          },
          selectedDeliveryDate: {
            //required
          },
        };
      }
    } else {
      if (this.saveTemplate) {
        return {
          selectedDeliveryTime: {
            // required,
            // hasTwentyFourHourBuffer
          },
          selectedFromCustomDate: {
            //required
          },
          selectedFromCustomTime: {
            //required
          },
          selectedToCustomDate: {
            // required,
            // checkToDateTimeNotBeforeFromDateTime
          },
          selectedToCustomTime: {
            //required
          },
          templateName: {
            //required
          },
          selectedDeliveryDate: {
            //required
          },
        };
      } else {
        return {
          selectedDeliveryTime: {
            //required,
            // hasTwentyFourHourBuffer
          },
          selectedFromCustomDate: {
            //required
          },
          selectedFromCustomTime: {
            //required
          },
          selectedToCustomDate: {
            //required
          },
          selectedToCustomTime: {
            //required
          },
          selectedDeliveryDate: {
            //equired
          },
        };
      }
    }
  },
};
</script>

<style scoped>
.divider-margin {
  margin-top: 30px;
  margin-bottom: 40px;
}
.container {
  max-width: 1290px;
}
</style>
