<template id="tmplTemplateDetails" >
  <section id="secTemplateDetails" class="container mb-3">
    <b-row id="rowSaveTemplate">
      <b-col id="colSaveTemplate">
        <b-form-checkbox id="cbSaveTemplate" v-model="saveTemplate">Save this order as a template for future use.
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row id="rowTemplateDetails" v-if="saveTemplate" class="mt-3">
      <b-col id="colTemplateName" lg="4" md="4" sm="12">
        <label id="lblTemplateName" for="txtTemplateName">Template Name</label>
        <b-form-input id="txtTemplateName" :state="chkState('templateName') == false ? false : null"
          v-model="templateName"></b-form-input>
        <b-form-invalid-feedback id="txtTemplateNameRequired" :state="chkState('templateName')">Template Name is required.</b-form-invalid-feedback>
      </b-col>
      <section id="SecCustomerTemplate" class="col row" v-if="canCreateCustomerTemplates || canCreateAdHocOrders">
        <b-col id="colCustomerTemplate" lg="3" md="3" sm="12" class="pd-10">
          <label id="lblCustomerTemplate"></label>
          <b-form-checkbox id="chkbxCustomerTemplate" v-if="isMultiUserTemplate && !canCreateAdHocOrders" name="chbxCustomerTemplate"
            v-model="isMultiUserTemplate">
            Customer Template
          </b-form-checkbox>
        </b-col>
        <b-col id="colAssignAllUsers" lg="3" md="3" sm="12" class="pd-10" v-if="isMultiUserTemplate">
          <label id="lblAssignAllUsers"></label>
          <b-form-checkbox id="chkbxAssignAllUsers" name="chbxCustomerTemplate" v-model="isAssignToAllUsersTemplate">
            Assign to All Users
          </b-form-checkbox>
        </b-col>
        <b-col id="colLoggedInUser" lg="6" md="6" sm="12" class="pd-10" v-if="isMultiUserTemplate">
          <UserTemplateAssignmentMultiselect id="selLoggedInUser" :disabled="isAssignToAllUsersTemplate"
            :customerId="loggedInUser.customerId">
          </UserTemplateAssignmentMultiselect>
        </b-col>
      </section>
    </b-row>
    <b-row id="rowSavePONumber" v-if="saveTemplate" class="mt-3">
      <b-col id="colSavePONumber">
        <b-form-group id="txtSavePO" label="Save PO or Reference Numbers associated with this order to the template:">
          <b-form-radio id="rdbtnSavePOTrue" v-model="saveTemplatePoNumber" name="some-radios" :value="true">Yes</b-form-radio>
          <b-form-radio id="rdbtnSavePOFalse" v-model="saveTemplatePoNumber" name="some-radios" :value="false">No</b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import {
  canCreateAdHocOrders,
  canCreateCustomerTemplates
} from "@/shared/utils/permissions";

import UserTemplateAssignmentMultiselect from  "@/components/multiselects/UserTemplateAssignmentMultiSelect"
  export default {
  name: "TemplateDetails.vue",
  props: ["ValidationModel"],
  components: {
    UserTemplateAssignmentMultiselect
  },
  computed: {
    ...mapGetters("user", ["loggedInUser"]),
    saveTemplate: {
      get() {
        return this.$store.state.newOrderTender.saveTemplate;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSaveTemplate", value);
      }
    },
    saveTemplatePoNumber: {
      get() {
        return this.$store.state.newOrderTender.saveTemplatePoNumber;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setSaveTemplatePoNumber", value);
      }
    },
    templateName: {
      get() {
        return this.$store.state.newOrderTender.templateName;
      },
      set(value) {
        this.$store.dispatch("newOrderTender/setTemplateName", value);
      }
    },
    isMultiUserTemplate: {
      get() {
        return this.$store.state.newOrderTemplate.isMultiUser;
      },
      set(value) {
        this.$store.dispatch("newOrderTemplate/setIsMultiUserTemplate", value);
      }
    },
    isAssignToAllUsersTemplate: {
      get() {
        return this.$store.state.newOrderTemplate.isAssignToAllUsers;
      },
      set(value) {
        this.$store.dispatch("newOrderTemplate/setIsAssignToAllUsers", value);
      }
    },
    canCreateAdHocOrders(){
      return canCreateAdHocOrders(this.$store);
    },
    canCreateCustomerTemplates(){
      return canCreateCustomerTemplates(this.$store);
    }
  },
  methods: {
    chkState(val) {
      const field = this.ValidationModel[val];
      return !field.$dirty || field.required;
    }
  },
  mounted() {
    this.isMultiUserTemplate = this.canCreateCustomerTemplates && !this.canCreateAdHocOrders;
  }
};
</script>


<style scoped>
</style>
